<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <div class="g col-12 text-center"><button class="btn btn-primary" v-b-modal.modal-1 @click="current = {}"><i class="fa fa-plus"></i> اضافة رد</button></div>
            <br>
            <table class="table table-hover table-bordered">
                <tbody>
                    <tr v-for="tag in tags" :key="tag._id">
                      <td @click="current = tag;" v-b-modal.modal-1>
                          <i class="fa fa-edit" v-if="tag.type == 'text'"></i>
                          <i class="fa fa-file" v-if="tag.type == 'file'"></i>
                          <i class="fa fa-microphone" v-if="tag.type == 'voice'"></i>
                          {{ tag.type == 'text' ? tag.text : tag.title }}
                      </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="modal-1" title="" hide-footer>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" v-model="current.type" value="text">
            <i class="fa fa-edit"></i>
            رسالة نصية
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" v-model="current.type" value="voice">
            <i class="fa fa-microphone"></i>
            رسالة صوتية
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" v-model="current.type" value="file">
            <i class="fa fa-file"></i>
            ملف / صورة
          </label>
        </div>
        <br>
        <div class="form-group" v-if="current.type == 'file' || current.type == 'voice'">
          <h5 for="">العنوان</h5>
          <input type="text"
            class="form-control" v-model="current.title" placeholder="اكتب هنا...">
        </div>
        <div class="form-group" v-if="current.type == 'file'">
          <h5 for="">الملف</h5>
          <input type="file" id="file"
            class="form-control">
        </div>
        <div class="form-group" v-if="current.type == 'text'">
          <h5 for="">الرسالة</h5>
          <textarea type="text"
            class="form-control" v-model="current.text" rows="5"></textarea>
        </div>
        <div class="col-12 text-center" v-if="current.type == 'voice'">
          <audio-recorder
          upload-url="some url"
          :time="30"
          :before-recording="callback"
          :after-recording="callback"
          :before-upload="callback"
          :successful-upload="callback"
          :failed-upload="callback"/>
          <br><br>
      <button class="btn btn-success" v-if="audioChunks" @click="sendVoice()"><i class="fa fa-check"></i> اعتماد هذا الصوت</button>
        </div>
        <div class="col-12 g">
          <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="save()">
              حفظ
            </button>
            <div v-if="current._id">
              <br><br>
              <button class="btn btn-sm btn-outline-danger" @click="deleteAdmin(current._id)">
                <i class="fa fa-trash"></i>
                حذف القالب
              </button>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  created(){
    var g = this;
    g.getAdmins()
    setInterval(() => {
        if($('[d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"]').is(":visible")){
            $('.ar-recorder__stop').fadeIn(0)
            $('[d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"]').parent().parent().html("<h1><i class='fa fa-times text-white'></i></h1>")
            $(".ar-recorder__stop").html("<h1><i class='fa fa-paper-plane text-success'></i></h1>")
        }
        $(".ar-recorder__records-limit").remove()
        $(".ar-recorder__time-limit").remove()
        $(".ar-player").remove()
        $(".ar-records").remove()
    }, 10);
    var x = setInterval(() => {
        if($("#file").length){
            clearInterval(x)
            $("#file").on("change", async function(){
                var input = $("#file")[0]
                g.current.file = await g.getBase64(input.files[0]);
                g.current.file_type = input.files[0].type;
                $("#file").val("")
                alert("تم رفع الملف, يمكنك الحفظ الآن")
            })
        }
    }, 10);
  },
  data(){
    return {
        jwt: JSON.parse(localStorage.getItem("user")).jwt,
        tags: [],
        current: {
        },
        audioChunks: "",

    }
  },
  methods: {

        getBase64(file) {
            return new Promise((resolveOuter) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolveOuter(reader.result.split("base64,")[1])
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            })
        },
    getAdmins(){
      var g = this;
      $.post(api + '/user/templates/list', {
          jwt: this.jwt
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              g.tags = r.response
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
    },
      callback (data) { var g = this;
          if(data.blob){
              g.audioChunks = data.blob
          }
      },
    save(){
      var g = this;
      $.post(api + '/user/templates/save', {
          jwt: this.jwt,
          id: this.current._id,
          text: this.current.text,
          file: this.current.file,
          title: this.current.title,
          type: this.current.type,
          file_type: this.current.file_type,
          
      }).then(function(r){
          if(r.status != 100){
              alert(r.response)
          }else{
              $("#modal-1___BV_modal_header_ > button").click()
              g.getAdmins()
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
    },
    deleteAdmin(id){
      if(confirm("متاكد من حذف الرد؟")){
        var g = this;
        $.post(api + '/user/templates/delete', {
            jwt: this.jwt,
            id: id
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                $("#modal-1___BV_modal_header_ > button").click()
                g.getAdmins()
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
      }
    },
    sendVoice(){
        var g = this;
        var reader = new FileReader();
        reader.readAsDataURL(g.audioChunks); 
        reader.onloadend = async function() {
            var base64data = reader.result;      
            g.current.file = base64data.split("base64,")[1];
            alert('تم اعتماد هذا الصوت, يمكنك الحفظ الآن')
            g.audioChunks = ""
        }
    },
  }
}
</script>

<style>
.ss:hover{
    background: #eee;
    cursor: pointer;
}
.ss.active{
    background: #ddd;
}
</style>