<template>
  <div class="container">
	<div class="row main-row no-gutters">
	  <div class="col-md-4 border-right contacts">
		<div class="settings-tray">
		  <img class="profile-image" :src="`https://ui-avatars.com/api/?name=${encodeURI(user.name)}&rounded=true&background=random`" alt="Profile img">
		  <span>
            {{ user.name }}
          </span>
          <span class="settings-tray--right" style="padding-top: 10px">
            <button class="btn btn-sm btn-primary fa fa-comment" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px" v-b-modal.modal-message @click="n_message = ''; to = '';">
            </button>
            <b-modal id="modal-message" title="ارسال رسالة جديدة" hide-footer>
                <div class="form-group">
                <label for="">رقم الهاتف (بالصيغة الدولية)</label>
                <input type="text"
                    class="form-control form-control-lg" @keyup="checkNumber()" v-model="to" :style="'text-align: center; ' + (error_check_number ? 'border: solid 2px red;' : 'border: solid 2px green;')" placeholder="+9665...." dir="ltr">
                </div>
                <div class="col-12 text-center text-danger" v-if="error_check_number">
                    {{ error_check_number }}
                    <br>
                </div>
                <div class="col-12 text-center text-success" v-if="!error_check_number && to">
                    <strong>
                        <i class="fa fa-check"></i>
                        لديه واتساب
                    </strong>
                </div>
                <div class="form-group">
                <label for="">الرسالة</label>
                <textarea class="form-control" v-model="n_message" placeholder="الرسالة هنا..." rows="3"></textarea>
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-success disabled" disabled v-if="error_check_number || !to || !n_message || n_message == '' || to == '' || n_message == ' '">
                        ارسال الآن
                    </button>
                    <button class="btn btn-success" v-if="!error_check_number && to && n_message" @click="sendMessageToNew()">
                        ارسال الآن
                    </button>
                </div>
            </b-modal>
            <button class="btn btn-sm btn-secondary fa fa-bell-slash" v-if="!muted" @click="muted = true" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
            </button>
            <button class="btn btn-sm btn-success fa fa-bell" v-if="muted" @click="muted = false" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
            </button>
            <button class="btn btn-sm btn-secondary fa fa-eye-slash" v-if="!ghost" @click="ghost = !ghost; ghostMode(); " style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
            </button>
            <button class="btn btn-sm btn-success fa fa-eye" v-if="ghost" @click="ghost = !ghost; ghostMode();" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
            </button>
            <button class="btn btn-sm btn-info fa fa-filter" v-b-modal.filter title="فتلرة المحادثات" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
            </button>
            <button v-if="allow_export_contacts" class="btn btn-sm btn-primary fa fa-download" v-b-modal.export title="تصدير الأرقام" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
            </button>
            <b-modal id="export" title="تصدير الارقام" size="sm" hide-footer>
                <button class="btn btn-primary btn-block text-left" @click="exportContact('text')">
                    <i class="fa fa-file"></i>
                    تصدير الارقام فقط في ملف نصي
                </button>
                <button class="btn btn-success btn-block text-left" @click="exportContact('excel')">
                    <i class="fa fa-file"></i>
                    تصدير الارقام Excel
                </button>
                <button class="btn btn-google btn-block text-left" @click="exportContact('google')">
                    <i class="fa fa-file"></i>
                    تصدير الارقام لجهات اتصال جوجل
                </button>
            </b-modal>
            <b-modal id="filter" title="فلترة المحادثلت" size="sm" hide-footer>
                <button class="btn btn-block btn-primary" @click="unread_only = 'no'; custom_tag = ''; refresh()">
                    <i class="fa fa-list"></i> عرض الكل
                </button>
                <button class="btn btn-block btn-outline-danger" @click="unread_only = 'yes'; refresh()">
                   <i class="fa fa-eye-slash"></i> الغير مقروءة
                </button>
                <hr>
                <div class="row">
                    <div class="col-12 col-lg-6" v-for="tag in tags" :key="tag._id">
                        <button class="btn btn-outline-secondary btn-block" @click="custom_tag = tag._id; refresh()">
                            <span :style="`color: ${tag.color};`">
                                <i :class="`fa fa-${tag.icon}`"></i>
                                {{ tag.name }}
                            </span>
                        </button>
                    </div>
                </div>
            </b-modal>
            <button class="btn btn-sm btn-success fa fa-home" title="العودة للرئيسية" @click="$router.push('/')" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
            </button>
		  </span>
		</div>
		<div class="search-box">
		  <div class="input-wrapper">
			<i class="fa fa-search"></i>
			<input placeholder="ابحث هنا..." style="padding: 7px" v-model="q" @keyup="searchx()" type="text">
		  </div>
		</div>
        <div class='contatslist'>
            <div class="col-12 text-center g" v-if="chats.length == 0 && !loaded">
                <img style="width: 100%; border-radius: 20px" :src="require('@/assets/images/get-chats.gif')">
                <br><br>
                <strong class="text-danger fadefade">
                    في انتظار المحادثات...<br><small class="text-success"><i>جاري المزامنة...</i></small></strong>
            </div>
            <div class="col-12 text-center g" v-if="chats.length == 0 && loaded && q != '' && q != ' ' && q">
                لا يوجد بحث مطابق.
            </div>
            <template v-for="alert in alerted_chats">
                <template v-for="chat in chats">
                    <div v-if="chat.type != 'hr' && alert.chat_user == chat.user" :key="alert._id + chat.user" @click="openChat(chat); seenAlertChat(alert._id)" :class="(chat.user == current.user ? 'cactive' : (chat.unreadCount > 0 ? 'calert' : '')) + ' ' + ('ctop2')">
                        <div :class="`friend-drawer friend-drawer--onhover chat ` + (' media ' + (chat.locked ? 'locked' : ''))" style="border-bottom: solid 1px #eee; position: relative">
                            <i class="fa fa-share shadow text-white bg-light-danger" style="position: absolute; bottom: 0px; left: 0px; background: #fff; border-radius: 5px; padding: 5px"> محولة لك {{ alert.forwarded_by }}</i>
                            <img class="profile-image" :src="chat.locked ? require('@/assets/images/avatars/locked.png') : (chat.avatar ? chat.avatar : require('@/assets/images/avatars/avatar2.png'))" alt="">
                            <div class="text">
                                <h6><span class="fa fa-thumb-tack text-danger" v-if="chat.pinned"></span>
                                <template v-for="tag in chat.tags">
                                    <span v-if="tags_obj[tag]" :key="tag" :style="`color: ${tags_obj[tag].color}; margin-right: 5px`" :title="tags_obj[tag].title">
                                        <i :class="`fa fa-${tags_obj[tag].icon}`"></i>
                                    </span>
                                </template>
                                    <span dir="ltr">{{ chat.title }} </span>
                                    </h6>
                                <span class="text-muted">
                                    <span style="color: #999" v-if="chat.lastMessage" class="lastmsg">
                                        <strong v-if="chat.lastMessage.fromme">أنت:</strong>
                                        <span v-if="chat.lastMessage.fromme">
                                            <span v-if="chat.lastMessage.ack == 1" class="text-muted">
                                                <i class="fa fa-check text-muted"></i>
                                            </span>
                                            <span v-if="chat.lastMessage.ack == 2" class="text-muted">
                                                <i class="fa fa-check text-muted"></i>
                                                <i class="fa fa-check text-muted" style="position: relative; right: -7px"></i>
                                            </span>
                                            <span v-if="chat.lastMessage.ack == 3">
                                                <i class="fa fa-check text-info"></i>
                                                <i class="fa fa-check text-info" style="position: relative; right: -7px"></i>
                                            </span>
                                        </span>
                                        <strong v-if="chat.lastMessage.type != 'chat'" v-html="chat.lastMessage.type.replace('ptt', '<i class=\'fa fa-microphone\'></i> رسالة صوتية').replace('revoked', '<i><i class=\'fa fa-ban\'></i> تم حذف هذه الرسالة</i>').replace('image', '<i class=\'fa fa-image\'></i> صورة')"></strong>
                                        {{ chat.lastMessage.body }}
                                    </span>
                                    <div v-if="chat.active_users">
                                        <div v-if="chat.active_users.length">
                                            <span v-for="u in chat.active_users" :key="u.name" class="g">
                                                <img :src="u.avatar ? u.avatar : `https://ui-avatars.com/api/?name=${encodeURI(u.name)}&rounded=true&background=random`" :title="u.name" width="20" height="20">
                                            </span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <span class="time text-muted small">{{ timeSince(new Date(chat.timestamp)) }}</span>
                            </div>
                    </div>
                </template>
            </template>
            <template v-for="(chat) in chats">
                <div v-if="chat.type != 'hr'" :key="chat.user" @click="openChat(chat)" :class="(chat.user == current.user ? 'cactive' : (chat.unreadCount > 0 ? 'calert' : '')) + ' ' + (chat.top || chat.pinned ? 'ctop' : '')">
                    <div :class="`friend-drawer friend-drawer--onhover chat ` + (' media ' + (chat.locked ? 'locked' : ''))" style="border-bottom: solid 1px #eee">
                        <img class="profile-image" :src="chat.locked ? require('@/assets/images/avatars/locked.png') : (chat.avatar ? chat.avatar : require('@/assets/images/avatars/avatar2.png'))" alt="">
                        <div class="text">
                            <h6><span class="fa fa-thumb-tack text-danger" v-if="chat.pinned"></span>
                            <template v-for="tag in chat.tags">
                                <span v-if="tags_obj[tag]" :key="tag" :style="`color: ${tags_obj[tag].color}; margin-right: 5px`" :title="tags_obj[tag].title">
                                    <i :class="`fa fa-${tags_obj[tag].icon}`"></i>
                                </span>
                            </template>
                                <span dir="ltr">{{ chat.title }} </span>
                                </h6>
                            <span class="text-muted">
                                <span style="color: #999" v-if="chat.lastMessage" class="lastmsg">
                                    <strong v-if="chat.lastMessage.fromme">أنت:</strong>
                                    <span v-if="chat.lastMessage.fromme">
                                        <span v-if="chat.lastMessage.ack == 1" class="text-muted">
                                            <i class="fa fa-check text-muted"></i>
                                        </span>
                                        <span v-if="chat.lastMessage.ack == 2" class="text-muted">
                                            <i class="fa fa-check text-muted"></i>
                                            <i class="fa fa-check text-muted" style="position: relative; right: -7px"></i>
                                        </span>
                                        <span v-if="chat.lastMessage.ack == 3">
                                            <i class="fa fa-check text-info"></i>
                                            <i class="fa fa-check text-info" style="position: relative; right: -7px"></i>
                                        </span>
                                    </span>
                                    <strong v-if="chat.lastMessage.type != 'chat'" v-html="chat.lastMessage.type.replace('ptt', '<i class=\'fa fa-microphone\'></i> رسالة صوتية').replace('revoked', '<i><i class=\'fa fa-ban\'></i> تم حذف هذه الرسالة</i>').replace('image', '<i class=\'fa fa-image\'></i> صورة')"></strong>
                                    {{ chat.lastMessage.body }}
                                </span>
                                <div v-if="chat.active_users">
                                    <div v-if="chat.active_users.length">
                                        <span v-for="u in chat.active_users" :key="u.name" class="g">
                                            <img :src="u.avatar ? u.avatar : `https://ui-avatars.com/api/?name=${encodeURI(u.name)}&rounded=true&background=random`" :title="u.name" width="20" height="20">
                                        </span>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <span class="time text-muted small">{{ timeSince(new Date(chat.timestamp)) }}</span>
                        </div>
                </div>
                <div v-if="chat.type == 'hr'" :key="chat.user">
                    <div class="strike text-muted">
                        <span></span>
                    </div>
                </div>
            </template>
            <button v-if="chats.length" @click="loadMoreChats()" class="btn text-primary btn-block" style="background: #fafafa;
            padding: 10px;
            margin-top: 5px;
            border-radius: 10px;">
                    <span v-if="!loadingChats">
                        <i class="fa fa-refresh"></i>
                        عرض المزيد من المحادثات
                    </span>
                    <span class="fadefade" v-if="loadingChats">
                        جاري الاستعلام...
                    </span>
                </button><br><br>
        </div>
	  </div>
	  <div class="col-md-8" v-if="!current.user">
            <div class="row" style="padding: 20px">
                <div class="col-12" style="padding: 20px">
                    المتصل الآن
                </div>
                <div class="col-2 col-lg-2" v-for="u in active_users" :key="u.title">
                    <b-avatar badge badge-variant="none" :src="u.avatar ? u.avatar : `https://ui-avatars.com/api/?name=${encodeURI(u.name)}&rounded=true&background=random`" :title="u.name" style="width: 64px !important; height: 64px !important; border-radius: 50%; border: solid 2px lightgreen">
                    <template #badge>
                        <img :src="require('@/assets/images/'+(u.ghost ? 'ghost_mode' : (u.isMobile ? 'mobile' : 'desktop' ))+'.png')" class="shadow" style="width: 24px !important;height: 24px !important; padding: 2px;position: relative; top: 7px;">
                    </template>
                    </b-avatar>
                    <br>{{ u.name }}
                </div>

                <div class="col-12 text-center" style="padding: 20px">
                    <h1>
                        <img :src="require('@/assets/images/banner.png')" style="width: 220px" alt="">
                    </h1>
                    <br><br>
                    <small class="text-muted">
                        <i class="fa fa-lock"></i>
                        جميع بياناتك محمية ومؤمنة ومشفرة.
                    </small>
                </div>
            </div>
      </div>
	  <div class="col-md-8" v-if="current.user">
		<div class="settings-tray">
			<div class="friend-drawer no-gutters friend-drawer--grey">
            <b-modal id="modal-pic" :title="'صورة الملف الشخصي'" hide-footer>
                <img :src="current.avatar ? current.avatar : require('@/assets/images/avatars/avatar2.png')" style="width: 100% !important; border-radius: 5px">
            </b-modal>
			<img class="profile-image" :src="current.avatar ? current.avatar : require('@/assets/images/avatars/avatar2.png')" v-b-modal.modal-pic>
			<div class="text">
			  <h6>
                <span v-if="allowToShowNumber()"  dir="ltr">
                    {{ current.title }}
                </span>
                <span v-if="!allowToShowNumber()" dir="ltr">
                    {{ current.title.substring(0,8) }}****
                </span>
              </h6>
			  <span class="text-muted">
                <span style="color: #999" class="lastmsg" v-if="allowToShowNumber()"  dir="ltr">
                    {{ current.user }}
                </span>
                <span style="color: #999" class="lastmsg"  dir="ltr" v-if="!allowToShowNumber()">
                    {{ current.user.substring(0,8) }}****
                </span>
                <span v-if="current.locked">
                    <i class="fa fa-lock"></i> المحادثة مقفلة بواسطة {{ current.locked.by }}
                </span>
                <div v-if="current.active_users">
                    <div v-if="current.active_users.length">
                        <span v-for="u in current.active_users" :key="u.name" class="g">
                            <img :src="u.avatar ? u.avatar : `https://ui-avatars.com/api/?name=${encodeURI(u.name)}&rounded=true&background=random`" :title="u.name" width="20" height="20">
                        </span>
                    </div>
                </div>
              </span>
			</div>
			<span class="settings-tray--right" style="margin-top: 10px">
                <button class="btn btn-sm btn-outline-danger fa fa-thumb-tack" title="الغاء تثبيت المحادثة" v-if="current.pinned" @click="unpin()" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
                </button>
                <button class="btn btn-sm btn-info fa fa-thumb-tack" title="تثبيت المحادثة" v-if="!current.pinned" @click="pin()" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
                </button>
                <button class="btn btn-sm btn-success fa fa-unlock" title="الغاء قفل المحادثة" v-if="((!current.locked) || (current.locked && current.locked.by == user.name)) && current.locked || (current.locked && (!user.pers || !user.pers && user.pers.includes('*')))" @click="unlock()" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
                </button>
                <button class="btn btn-sm btn-secondary fa fa-lock" title="قفل المحادثة" v-if="!current.locked" @click="lock()" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
                </button>
                <button v-b-modal.tags class="btn btn-sm btn-success fa fa-tag" title="التصنيفات" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
                <strong v-if="current.tags.length"><br>{{ current.tags.length }}</strong>
                </button>
                <b-modal id="tags" :title="'التصنيفات'" hide-footer size="sm">
                    <button class="btn btn-block" v-for="tag in tags" :key="tag._id" @click="doTag(tag._id)" :class="current.tags.includes(tag._id) ? 'active-tag' : ''">
                        <span :style="`color: ${tag.color};`">
                            <i :class="`fa fa-${tag.icon}`"></i>
                            {{ tag.name }}
                        </span>
                    </button>
                </b-modal>
                <button v-b-modal.notes class="btn btn-sm btn-danger fa fa-edit" title="الملاحظات" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;">
                    <strong v-if="current.notes.length"><br>{{ current.notes.length }}</strong>
                </button>
                <b-modal id="notes" title="الملاحظات" hide-footer>
                    <input class="form-control form-control-lg" type="text" placeholder="اكتب ملاحظة... ثم اضغط Enter" v-model="n_note" style=" border: 2px solid #ddd" id="note">
                    <div v-for="(note, ii) in current.notes" :key="ii" style="margin-top: 10px">
                        <span>
                            <img :src="`https://ui-avatars.com/api/?name=${encodeURI(note.by)}&rounded=true&background=random`" style="width: 24px; height: 24px;" alt="">
                            {{ note.body }}
                            <br><small class="text-muted">{{ note.by }} ~ {{ note.date.replace("T", ' ').split('.')[0] }}</small>
                        </span>
                    </div>
                </b-modal>
                <button v-b-modal.forward class="btn btn-sm btn-primary fa fa-reply-all" title="تحويل المحادثة" style="border-radius: 50%; padding: 5px; width: 32px; height: 32px; margin-right: 5px;"></button>
                <b-modal id="forward" title="تحويل المحادثة" hide-footer size="lg">
                    <div class="col-12 g">
                        <button class="btn btn-outline-danger btn-sm" v-if="!send_message_with_forward" @click="send_message_with_forward = !send_message_with_forward" style="border-radius: 0px">
                            <i class="fa fa-whatsapp"></i>
                            ارسال تنبيه بالتحويل على الواتس اب
                            (غير فعالة)
                        </button>
                        <button class="btn btn-success btn-sm" v-if="send_message_with_forward" @click="send_message_with_forward = !send_message_with_forward" style="border-radius: 0px">
                            <i class="fa fa-whatsapp"></i>
                            ارسال تنبيه بالتحويل على الواتس اب
                            (فعالة)
                        </button>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="card card-body border shadow">
                                <strong>
                                    <i class="fa fa-info"></i>
                                    الاساسية
                                </strong>
                                <br>
                                <button class="btn btn-block btn-danger text-left" @click="forward(current.user, 'user')">
                                    <i class="fa fa-user"></i>
                                    تحويل للمسؤول
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="card card-body border shadow">
                                <strong>
                                    <i class="fa fa-users"></i>
                                    للزملاء
                                </strong>
                                <br>
                                <button v-for="a in admins" :key="a._id" class="text-left btn btn-block btn-outline-secondary" @click="forward(current.user, 'admin', a._id)">
                                    <i class="fa fa-user"></i>
                                    {{ a.name }}
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="card card-body border shadow">
                                <strong>
                                    <i class="fa fa-tag"></i>
                                    تصنيف معين
                                </strong>
                                <br>
                                <button class="text-left btn btn-block" v-for="tag in tags" :key="tag._id" :class="current.tags.includes(tag._id) ? 'active-tag' : ''" @click="doTag(tag._id); forward(current.user, 'tag', tag._id)">
                                    <i class="fa fa-tag"></i>
                                    {{ tag.name }}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </span>
		  </div>
		</div>
		<div class="chat-panel messages-container" id="messages" style="height: 85vh; overflow-y: scroll !important">
        <div class="col-12 g text-center">
            <img :src="require('@/assets/images/loading-classic.gif')" style="width: 100px; border-radius: 10px" v-if="loading_chat" alt="">
            <button v-if="(messages.length && messages.length >= messagesLimit) ||loadingChats" @click="messagesLimit = messagesLimit+50;loadingChats = true" class="btn text-primary" style="background: #fafafa;
                padding: 10px;
                margin-top: 5px;
                border-radius: 10px;">
                <span v-if="!loadingChats">
                    <i class="fa fa-refresh"></i>
                    عرض المزيد من الرسائل
                </span>
                <span class="fadefade" v-if="loadingChats">
                    جاري الاستعلام...
                </span>
            </button>
        </div>
        <template v-for="(message, i) in messages" >              
        <div class="row no-gutters" :id="`message_${message._data ? message._data.id.id : '4'}`" :key="i">
            <div :class="`col-md-6 ` + (!message.fromMe ? 'offset-md-6' : '')" style="position: relative">
                <div :class="`chat-bubble chat-bubble--` + (!message.fromMe ? 'right' : 'left')" >
                    <div v-if="message._data">
                        <span v-if="message.author && current.isGroup" style=" padding: 2px">
                           <span>
                            <img :src="require('@/assets/images/avatars/avatar2.png')" alt="" style="width: 26px; border-radius: 50%">{{ message.author ? message.author.split("@")[0] : '' }}</span>
                        </span>
                    <div v-if="message._data.quotedMsg">
                        <div v-if="message._data.quotedMsg.type">
                        <small v-if="message._data.quotedMsg.type != 'chat'" @click="goToMessage(message._data.quotedStanzaID)">
                        <a :class="(!message.fromMe ? 'text-white' : '')" :href="`#message_` + message._data.quotedStanzaID">
                            <i>
                                <i class="fa fa-arrow-left"></i> رد على:
                            {{ message._data.quotedMsg.type }}
                            </i>
                        </a>
                        </small>
                        <small v-if="message._data.quotedMsg.type == 'chat'" @click="goToMessage(message._data.quotedStanzaID)">
                        <a :class="(!message.fromMe ? 'text-white' : '')" :href="`#message_` + message._data.quotedStanzaID">
                            <i>
                                <i class="fa fa-arrow-left"></i> رد على: "{{
                            message._data.quotedMsg.body
                        }}"
                            </i>
                        </a>
                        </small>
                    </div>
                    </div>
                    </div>
                    <div v-if="message.isForwarded">
                    <span><i class="fa fa-reply"></i> رسالة محولة</span>
                    </div>
                    <span class="text-dark" v-if="message.body != 'undefined'" style="padding: 0px !important; height: auto !important">
                    <img :src="require('@/assets/images/loading.svg')" v-if="message.body == 'جاري الارسال...'" style="width: 24px" alt="">
                    <div v-if="message.links && message.links.length > 0" :class="!message.fromMe ? 'text-white ccx' : 'ccx'" style="overflow: hidden !important" v-html="linkify(message.body)"></div></span>
                    <span v-if="!message.links || message.links.length == 0" :class="!message.fromMe ? 'text-white ccx' : 'ccx'" style="overflow: hidden !important" v-html="linkify(message.body)"></span>
                    <span v-if="message.type == 'revoked'">
                        <i>
                            <i class="fa fa-ban"></i>
                            تم حذف هذه الرسالة.
                        </i>
                    </span>
                    <span
                    v-if="message.hasMedia"
                    v-b-modal.modal-1x
                    @click="
                    if(loading2 || decodedHTML[message._data.id.id]){return;}
                        type = message.type;
                        messageMedia = {};
                        getMedia(current.user, message.mediaKey, message);
                    "
                    style="cursor: pointer"
                    >
                    <br />
                    <template v-if="message._data">
                        <div v-if="decodedHTML[message._data.id.id] && decodedHTML[message._data.id.id] != 'waiting'" v-html="decodedHTML[message._data.id.id]" >
                        
                        </div>
                        <span v-if="decodedHTML[message._data.id.id] == 'waiting'">
                            <img :src="require('@/assets/images/loading.svg')" style="width: 24px" alt=""> جاري العرض...
                        </span>
                        <template v-if="!decodedHTML[message._data.id.id]">
                            <img
                                v-if="
                                message.type != 'ptt' &&
                                (message.type == 'document' ||
                                    message.type == 'pdf' ||
                                    message.type == 'image' ||
                                    message.type == 'video' ||
                                    message.type == 'sticker' ||
                                    message.type == 'audio')
                                "
                                :src="require('@/assets/images/' + message.type + '.png')"
                                style="width: 30px"
                            />
                            <img
                                v-if="message.type == 'ptt'"
                                :src="require('@/assets/images/ppt.png')"
                                style="width: 30px"
                            />
                            <span v-if="message.type != 'ptt'">تشغيل / عرض</span>
                            <span v-if="message.type == 'ptt'">تشغيل المقطع الصوتي</span>
                        </template>
                    </template>
                    </span>
                    <br />
                    <small>{{ message.time }}
                        <span v-if="message.fromMe">
                            <span v-if="message.ack == 1" class="text-muted">
                                <i class="fa fa-check text-muted"></i>
                            </span>
                            <span v-if="message.ack == 2" class="text-muted">
                                <i class="fa fa-check text-muted"></i>
                                <i class="fa fa-check text-muted" style="position: relative; right: -7px"></i>
                            </span>
                            <span v-if="message.ack == 3">
                                <i class="fa fa-check text-info"></i>
                                <i class="fa fa-check text-info" style="position: relative; right: -7px"></i>
                            </span>
                        </span>
                        <a href="javascript:;" v-if="message.time" @click="replyToMessage(message)">
                            <small :class="(!message.fromMe ? 'text-white' : '')">
                                <i class="fa fa-reply"></i> رد
                            </small>
                        </a>
                        <a href="javascript:;" v-if="(i >= messages.length - 5) && message.time && message.fromMe && message.type != 'revoked'" @click="deleteMessage(message)">
                            <small :class="(!message.fromMe ? 'text-danger' : 'text-danger')">
                                <i class="fa fa-trash"></i> حذف
                            </small>
                        </a>
                        <template v-if="message.sent_by">
                            <img :src="`https://ui-avatars.com/api/?name=${encodeURI(message.sent_by.name ? message.sent_by.name : message.sent_by)}&rounded=true&background=random`" alt="" style="width: 24px"> {{ message.sent_by.name ? message.sent_by.name : message.sent_by }}
                        </template>
                    </small>
                    <small v-if="message.reactions && message.reactions.length" style="border-radius: 10px; background: #fff; position: absolute; bottom: 0px; right: 10px; bottom: -15px; padding: 5px" class='shadow'>
                        <span v-for="(reac, ix) in message.reactions" :key="ix+'87878'">
                            <span v-if="reac != '❤'" style="font-size: 14px">{{ reac }}</span>
                            <img :src="require('@/assets/images/heart.png')" v-if="reac == '❤'" width="18">
                        </span>
                    </small>
                </div>
            </div>
        </div>
        </template>
		</div>
        <template v-if="isMobile">
            <br><br><br><br><br><br>
        </template>
        <div :style="`position: absolute; bottom: ${(isMobile ? '50px' : '0px')}; width: 100%`">

        <div class="templates" v-if="message == '/' || message == '\\'" style="position: relative; top: 25px">
            <div class="card card-body" style="padding-bottom: 0px;">
                <div class="row">
                    <div class="col-12 text-center g text-danger" v-if="templates.length == 0">
                        لا يوجد اي ردود جاهزة.
                    </div>
                    <div class="col-12 g tem" @click="sendTemplate(template);" v-for="template in templates" :key="template._id">
                        <span v-if="template.type == 'text'">
                            <i class="fa fa-edit"></i>
                            {{ template.text }}
                        </span>
                        <span v-if="template.type == 'voice'">
                            <i class="fa fa-microphone"></i>
                            {{ template.title }}
                        </span>
                        <span v-if="template.type == 'file'">
                            <i class="fa fa-file"></i>
                            {{ template.title }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
            <div class="col-12" v-if="reply._data" style="position: relative; top: 15px">
                <div class="alert alert-primary g">
                    <a href="javascript:;" @click="reply = {}" class="text-danger"><i class="fa fa-times"></i> الغاء</a>
                    الرد على: {{ reply.body && reply.body != ' ' && reply.body != '' ? reply.body : reply._data.type }} 
                    <small class="text-muted">الرد بنص فقط.</small>
                </div>
            </div>
            <input type="file" :id="'file'" style="display:none;">

                    <div class="col-12 text-center" v-if="current.locked">
                        <span class="text-danger text-center">
                            <i class="fa fa-lock"></i> المحادثة مقفلة بواسطة {{ current.locked.by }}
                        </span>
                    </div>
            <div class="chat-box-tray" v-if="(!current.locked) || (current.locked && current.locked.by == user.name)" >
            <i class="fa fa-clipboard hover" @click="message == '/' ? message = '' : message = '/'"></i>
            <input type="text" style="padding-right: 15px"  v-model="message" @keypress="typing()" placeholder="اكتب هنا..." id="message">
            <i class="fa fa-paper-plane hover" @click="send()"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <i class="fa fa-microphone hover" @click="record()" v-b-modal.modal-2></i>
            <i class="fa fa-paperclip hover" @click="openFile()"></i>
            </div>
        </div>

        <b-modal id="modal-2" title="تسجيل صوتي" hide-footer>
        <div class="col-12 text-center">
        <audio-recorder
            upload-url="some url"
            :attempts="1"
            :time="30"
            :before-recording="callback"
            :after-recording="callback"
            :before-upload="callback"
            :successful-upload="callback"
            :failed-upload="callback"/>
            <br><br>
        <button class="btn btn-success" v-if="audioChunks" @click="sendVoice()"><i class="fa fa-paper-plane"></i> ارسال الآن</button>
        </div> 
        </b-modal>
        <b-modal id="modal-1" size="lg" title="تشغيل المحتوى" hide-footer>
            <div class="col-12 text-center">
                <div class="c col-12 text-center" v-if="loading2">
                <img :src="require('@/assets/images/loading.svg')" /><br />
                جاري تشغيل/عرض المحتوى...<br /><br /><br />
                </div>
                <div v-if="!loading2">
                <span v-if="type == 'image'">
                    <img
                    :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                    style="width: 100%"
                    />
                </span>
                <span v-if="type == 'sticker'">
                    <img
                    :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                    style="width: 100%"
                    />
                </span>
                <span v-if="type == 'document'">
                    <a
                    target="_blank"
                    class="btn btn-lg btn-success"
                    :href="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                    >
                    <i class="fa fa-file"></i>
                    عرض الملف</a
                    >
                </span>
                <span v-if="type == 'document'">
                    <br><br>
                    <a
                    target="_blank"
                    class="btn btn-lg btn-info"
                    download=""
                    :href="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                    >
                    <i class="fa fa-download"></i>
                    تحميل الملف</a
                    >
                </span>
                <span v-if="type == 'audio' || type == 'ptt'">
                    <audio controls>
                    <source
                        :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                        :type="messageMedia.mimetype"
                    />
                    </audio>
                </span>
                <span v-if="type == 'video'">
                    <video width="320" height="240" controls>
                    <source
                        :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                        :type="messageMedia.mimetype"
                    />
                    </video>
                </span>
                </div>
            </div>
            </b-modal>
	  </div>
	</div>
  </div>
</template>


<script>
import Ripple from "vue-ripple-directive";
import { BModal, VBModal } from "bootstrap-vue";
import { BDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Templates from './templates.vue';
export default {
  components: {
    BModal,
    BDropdown,
    BDropdownItem,
    BAvatar
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
    data(){
        return { 
            alerted_chats: [],
            isMobile: screen.width <= 480,
            user: JSON.parse(localStorage.getItem('user')),
            loading2: false,
            loaded: false,
            audio: null,
            ghost: false,
            loading_chat: false,
            admins: [],
            chats: [],
            current: {title:""},
            messages: [],
            muted: false,
            messageMedia: {},
            loadingChats: false,
            decodedHTML: {},
            message: "",
            type: "",
            started: false,
            done: false,
            audioChunks: "",
            isTop: false,
            n_note: "",
            tags: [],
            tags_obj: {},
            n_message: "",
            to: "",
            error_check_number: false,
            active_users: [],
            templates: [],
            voices: {},
            chatsLimit: 50,
            q: "",
            custom_tag: "",
            unread_only: "",
            messagesLimit: 50,
            reply: {},
            send_message_with_forward: false,
            allow_export_contacts: false
        }
    },
    created(){
        var g = this;
        if(g.pers){
            if(!g.pers.includes("*")){
                g.allow_export_contacts = false;
            }else{
                g.allow_export_contacts = true;
            }
        }else{
            g.allow_export_contacts = true;
        }
        $.post(api + '/user/tags/list', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.tags = r.response
                g.tags.forEach(function(a){
                    g.tags_obj[a._id] = a
                })
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/user/devices/admins', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.admins = r.response
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/user/templates/list', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.templates = r.response
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $(document).keyup(function(e) {
            if (e.keyCode == 27) { // Esc
                g.current = {}
            }
            if (e.keyCode == 13 && $("#message").is(":focus")) { // Enter
                g.send()
            }
            if (e.keyCode == 13 && $("#note").is(":focus")) { // Note
                g.sendNote()
            }
        });
        if(!window.location.hash){
            window.location.hash = "_"
            location.reload()
        }
        var x = setInterval(() => {
            if($("#file").length){
                clearInterval(x)
                $("#file").on("change", async function(){
                    var input = $("#file")[0]

                    g.reply = {}
                    g.$socket.emit("send", {
                        to: g.current._id,
                        type: "file",
                        body: await g.getBase64(input.files[0]),
                        mimetype: input.files[0].type,
                        reply: g.reply,
                        limit: g.messagesLimit
                    })
                    $("#file").val("")
                    g.messages.push({
                        body: "جاري الارسال...",
                        fromMe: true
                    })
                    $('#messages').animate({ scrollTop: 99999999 }, );
                    setTimeout(() => {
                        g.refresh()
                    }, 1000)
                })
            }
        }, 10);
        g.sockets.subscribe("active_users", function(data){
            g.active_users = data
        })
        g.sockets.subscribe("message", function (data) {
            setTimeout(() => {
                if(g.audio){
                    g.audio.pause();
                }
                if(!g.muted){
                    g.audio = new Audio(require("@/assets/sounds/new_message.mp3"));
                    g.audio.play();
                }
                //g.$socket.emit("request-chats", "")
                if(g.current.user){
                    g.$socket.emit("request-messages", {
                        user: g.current.user,
                        limit: g.messagesLimit
                    })
                }
            }, 100);
        })
        setTimeout(() => {
            if(g.chats.length == 0){
                location.reload()
            }else{
                g.loaded = true;
            }
        }, 10000);
        setInterval(() => {
            if($(".fadefade").length){
                if($(".fadefade").is(":visible")){
                    $(".fadefade").fadeOut('slow');
                }else{
                    $(".fadefade").fadeIn('slow');}
            }
        }, 500);
        Array.prototype.insert = function ( index, ...items ) {
            this.splice( index, 0, ...items );
        };
        g.sockets.subscribe("alerted-chats", function (data) {
            g.alerted_chats = data;
        })
        g.sockets.subscribe("chats", function (data) {
            g.chats = data
            g.loadingChats = false;
            g.chats.sort(function(a, b){
                if(a.top < b.top) { return 1; }
                if(a.top > b.top) { return -1; }
                return 0;
            })
            var c = 0, i = 0;
            g.chats.forEach(function(a){
                i++
                if(a.user == g.current?.user){
                    g.current = a
                }
                if(a.top){
                    c = i;
                }
            })
            if(c != 0){
                g.chats.insert(c, {
                    type: 'hr'
                });
                g.isTop = true
            }else{
                g.isTop = false
            }
        })
        g.sockets.subscribe("messages", function (data) {
            g.messages = data
            if(g.loading_chat){
                setTimeout(() => {
                    $('#messages').animate({ scrollTop: 99999999 }, );
                    $("#message").focus()
                }, 100);
            }
            g.loading_chat = false;
        })
        g.$socket.emit("request-alerted-chats")
        setInterval(() => {
            g.$socket.emit("request-alerted-chats")
        }, 5000);
        g.$socket.emit("request-chats")
        g.sockets.subscribe("messageMedia", function (data) {
            g.messageMedia = data;
            for (const [key, value] of Object.entries(g.decodedHTML)) {
                if(value == 'waiting'){
                    if(g.type == 'audio' || g.type == 'ptt'){
                        g.decodedHTML[key] = `<audio controls>
                        <source
                            src="data:${g.messageMedia.mimetype};base64,${g.messageMedia.data}"
                            type="${g.messageMedia.mimetype}" autoplay="1"
                        />
                        </audio>`
                    }
                    if(g.type == 'image'){
                        g.decodedHTML[key] = `
                        <a href='data:${g.messageMedia.mimetype};base64,${g.messageMedia.data}' download><img
                            src="data:${g.messageMedia.mimetype};base64,${g.messageMedia.data}"
                            style="width: 100%; border-radius: 10px"
                        /></a>
                        `
                    }
                    if(g.type == 'sticker'){
                        g.decodedHTML[key] = `
                        <a href='data:${g.messageMedia.mimetype};base64,${g.messageMedia.data}' download><img
                            src="data:${g.messageMedia.mimetype};base64,${g.messageMedia.data}"
                            style="width: 100%; border-radius: 10px"
                        /></a>
                        `
                    }
                    if(g.type == 'document'){
                        g.decodedHTML[key] = `
                        <a
                            target="_blank"
                            class="btn btn-lg btn-secondary btn-sm"
                            download=""
                            href="data:${g.messageMedia.mimetype};base64,${g.messageMedia.data}"
                            >
                            <i class="fa fa-download"></i>
                            تحميل الملف</a
                            >
                        `
                    }
                    setTimeout(() => {
                        if(g.type == 'video'){
                            g.decodedHTML[key] = `
                            <video style='width: 100%; border-radius: 10px' height="240" controls>
                            <source
                                src="data:${g.messageMedia.mimetype};base64,${g.messageMedia.data}"
                                type="${g.messageMedia.mimetype}"
                            />
                            </video>
                            `
                        }
                    }, 100);
                }
            }
            g.loading2 = false;
        })
        g.sockets.subscribe("ghost_mode_status", function (data) {
            g.ghost = data
        })
        g.you_are_alive = setTimeout(() => {
            location.reload()
        }, 10000);
        g.sockets.subscribe("you_are_alive", function (data) {
            clearTimeout(g.you_are_alive)
            g.you_are_alive = setTimeout(() => {
                location.reload()
            }, 10000)
        })
        g.sockets.subscribe("download-file", function(data){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();
            today = dd + "/" + mm + "/" + yyyy;
            var link = document.createElement("a");
            var id = 'c'+ Math.random().toString().split(".")[1]
            link.setAttribute("href", data.data);
            link.setAttribute("id", id);
            link.setAttribute("download", "octo_"+today+"." + data.type);
            document.body.appendChild(link);
            link.click()
        })
        setInterval(() => {
            g.refresh()
        }, 2000);
        setInterval(() => {
            if($('[d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"]').is(":visible")){
                $('.ar-recorder__stop').fadeIn(0)
                $('[d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"]').parent().parent().html("<h1><i class='fa fa-times text-white'></i></h1>")
                $(".ar-recorder__stop").html("<h1><i class='fa fa-paper-plane text-success'></i></h1>")
            }
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-recorder__records-limit").remove()
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-recorder__time-limit").remove()
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-player").remove()
            $("#modal-2___BV_modal_body_ > div > div > div > div.ar-records").remove()
        }, 10);
    },
    methods: {
        log(l){
            console.log(l)
        },
        exportContact(type){
            var g = this;
            g.$socket.emit("export-contacts", {
                type: type
            })
        },
        sendTemplate(template){
            var g = this;
            if(template.type == 'text'){
                g.message = template.text;
                g.send()
            }
            if(template.type == 'voice'){
                g.$socket.emit("send", {
                    to: g.current._id,
                    type: "file",
                    body: template.file,
                    mimetype: "audio/mp3",
                    reply: g.reply,
                    limit: g.messagesLimit
                })
                if(g.message == "/" || g.message == "\\"){
                    g.message = ""
                }
                g.reply = {}
                g.messages.push({
                    body: "جاري الارسال...",
                    fromMe: true
                })
                g.refresh()
            }
            if(template.type == 'file'){
                g.$socket.emit("send", {
                    to: g.current._id,
                    type: "file",
                    body: template.file,
                    mimetype: template.file_type,
                    reply: g.reply,
                    limit: g.messagesLimit
                })
                if(g.message == "/" || g.message == "\\"){
                    g.message = ""
                }
                g.reply = {}
                g.messages.push({
                    body: "جاري الارسال...",
                    fromMe: true
                })
                g.refresh()
            }
        },
        replyToMessage(message){
            var g = this;
            g.reply = message;
        },
        allowToShowNumber(){
            if(!this.user.admin_id || this.user?.pers?.includes('show-numbers') || this.user?.pers?.includes('*')){
                return true;
            }else{
                return false;
            }
        },
        seenAlertChat(id){
            this.$socket.emit("seen-alert-chat", id)
        },
        timeSince(date) {

            var seconds = Math.floor((new Date() - date*1000) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " سنة";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " شهر";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " يوم";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " ساعة";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " دقيقة";
            }
            return Math.floor(seconds) + " ثانية";
            },
        linkify(inputText) {
            var replacedText, replacePattern1, replacePattern2, replacePattern3;

            //URLs starting with http://, https://, or ftp://
            replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

            //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
            replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
            replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

            //Change email addresses to mailto:: links.
            replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
            replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

            return replacedText;
        },
        goHome(){
            window.location = '/'
        },
        openFilter(){
            $(`.b-dropdown:contains('فلترة)`).click()
        },
        openFile(){
            var g = this;
            var current = g.current
            $("#file").click()
        },
        getMedia(user, mediaKey, message) {
            var g = this;
            g.loading2 = true;
            g.$socket.emit("request-message-media", {
                user: user,
                key: mediaKey,
                limit: g.messagesLimit
            })
            g.decodedHTML[message._data.id.id] = "waiting";
        },
        goToMessage(id){
            $("#message_" + id).fadeOut(0);
            $("#message_" + id).fadeIn('slow');
            setTimeout(() => {
                $("#message_" + id).fadeOut(0);
                $("#message_" + id).fadeIn('slow');
                window.location.hash = origin_id;
            }, 100);
        },
        ghostMode(){
            var g = this;
            g.$socket.emit("ghost_mode")
        },
        callback (data) { var g = this;
            if(data.blob){
                g.audioChunks = data.blob
            }
        },
        loadMoreChats(){
            var g = this;
            g.loadingChats = true;
            g.chatsLimit = g.chatsLimit+50;
            g.$socket.emit("request-chats", {
                limit: g.chatsLimit,
                q: g.q
            })
        },
        searchx(){
            var g = this;
            g.$socket.emit("request-chats", {
                limit: g.chatsLimit,
                q: g.q
            })
        },
        deleteMessage(message){
            var g = this;
            if(!g?.user?.pers || g?.user?.pers?.includes("*")
            || (g.user?.admin_id && g.user?.admin_id == message?.sent_by?.admin_id)
            || (!g.user?.admin_id && g.user._id == message?.sent_by?.user_id)
            ){
                if(confirm("متأكد من حذف الرسالة لدى الجميع؟")){
                    g.$socket.emit("delete-message", {
                        limit: g.chatsLimit,
                        id: message._data.id.id,
                        user: g.current.user
                    })
                }
            }
        },
        send(){
            var g = this;
            if(this.message?.trim() != ""){
                g.$socket.emit("send", {
                    to: g.current._id,
                    type: "text",
                    body: g.message,
                    reply: g.reply,
                    limit: g.messagesLimit
                })
                g.reply = {}
                g.messages.push({
                    body: "جاري الارسال...",
                    fromMe: true
                })
                $('#messages').animate({ scrollTop: 99999999 }, );
                g.refresh()
                g.message = "";
            }
        },
        getBase64(file) {
            return new Promise((resolveOuter) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolveOuter(reader.result.split("base64,")[1])
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            })
        },
        openChat(chat){
            var g = this;
            g.loading_chat = true;
            g.decodedHTML = {};
            g.messagesLimit = 50;
            this.current = chat;
            g.messages = []
            g.$socket.emit("request-messages", {
                user: chat.user,
                limit: g.messagesLimit
            })
        },
        refresh(){
            var g = this;
            g.$socket.emit("request-chats", {
                limit: g.chatsLimit,
                q: g.q,
                unread_only: g.unread_only,
                custom_tag: g.custom_tag
            })
            if(g.current.user){
                g.$socket.emit("request-messages", {
                    user: g.current.user,
                    limit: g.messagesLimit
                })
            }
        },
        checkNumber(){
            var g = this;
            g.$socket.emit("request-check-number", g.to)
            g.sockets.subscribe("check-number", function (data) {
                g.error_check_number = data;
            })
        },
        record(){
            this.audioChunks = null;
        },
        sendVoice(){
            var g = this;
            var reader = new FileReader();
            reader.readAsDataURL(g.audioChunks); 
            reader.onloadend = async function() {
                var base64data = reader.result;      
                
                    g.reply = {} 
                g.$socket.emit("send", {
                    to: g.current._id,
                    type: "file",
                    body: base64data.split("base64,")[1],
                    mimetype: "audio/mp3",
                    reply: g.reply,
                    limit: g.messagesLimit
                })
                g.reply = {}
                g.messages.push({
                    body: "جاري الارسال...",
                    fromMe: true
                })
                $("#modal-2___BV_modal_header_ > button").click()
                $('#messages').animate({ scrollTop: 99999999 }, );
                setTimeout(() => {
                    g.refresh()
                })
            }
        },
        pin(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري تثبيت المحادثة...",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
            this.$socket.emit("pin-chat", g.current._id)
                g.current.pinned = true;
                g.current.top = true;
                g.hotrefresh()
        },
        unpin(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري الغاء تثبيت المحادثة...",
                    icon: "EditIcon",
                    variant: "danger",
                  },})
            this.$socket.emit("unpin-chat", g.current._id)
                g.current.pinned = false;
                g.current.top = false;
                g.hotrefresh()
        },
        sendNote(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري اضافة الملاحظة...",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.$socket.emit("note", {
                    user: g.current._id,
                    body: g.n_note
                })
                g.n_note = ""
                g.hotrefresh()
        },
        lock(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري قفل المحادثة...",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
            this.$socket.emit("lock", g.current._id)
            g.current.locked = {
                by: g.user.socket_id
            }
                g.hotrefresh()
        },
        unlock(){
            var g = this;
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري الغاء قفل المحادثة...",
                    icon: "EditIcon",
                    variant: "danger",
                  },})
            this.$socket.emit("unlock", g.current._id)
            g.current.locked = undefined;
                g.hotrefresh()
        },
        forward(chat_user, type, id=""){
            var g = this;
            g.$toast({
                component: ToastificationContent,
                props: {
                title: "جاري التحويل...",
                icon: "EditIcon",
                variant: "success",
                }
            })
            this.$socket.emit("alert-chat", {
                chat_user: chat_user,
                type: type,
                id: id,
                send_message_with_forward: g.send_message_with_forward
            })
        },
        doTag(id){
            var g = this;
            if(g.current.tags.includes(id)){
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري ازالة التصنيف...",
                    icon: "EditIcon",
                    variant: "danger",
                  },})
                this.$socket.emit("remove-tag", {
                    user: g.current._id,
                    tag: id
                })
            }else{
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "جاري اضافة التصنيف...",
                    icon: "EditIcon",
                    variant: "success",
                  },})
                this.$socket.emit("add-tag", {
                    user: g.current._id,
                    tag: id
                })
            }
                g.hotrefresh();
        },
        typing(){
            var g = this;
            this.$socket.emit("typing", g.current._id)
        },
        hotrefresh(){
            var g = this;
            g.refresh()
        },
        sendMessageToNew(){
            var g = this;
            var number = g.to;
            number = number.replace(" ", "")
            number = number.replace("+", "")
            number = number?.replace(/\D/g, '') + "@c.us"
            g.$socket.emit("send", {
                to: number,
                type: "text",
                body: g.n_message
            })
            setTimeout(() => {
                $("#modal-message___BV_modal_header_ > button").click()
            }, 1000);
            setTimeout(() => {
                if(g.chats[0]){
                    if(g.chats[0]?.user?.includes(number.substring(5,11))){
                        g.openChat(g.chats[0])
                    }else{
                        setTimeout(() => {
                            if(g.chats[0]){
                                if(g.chats[0]?.user?.includes(number.substring(5,11))){
                                    g.openChat(g.chats[0])
                                }
                            }
                        }, 3000);
                    }
                }else{
                    setTimeout(() => {
                        if(g.chats[0]){
                            if(g.chats[0]?.user?.includes(number.substring(5,11))){
                                g.openChat(g.chats[0])
                            }
                        }
                    }, 3000);
                }
            }, 1000);
        }
    }
}
</script>

<style lang="scss">
$blue: #74b9ff;

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}
.chat.active{
    border-bottom: solid 2px #64CCC5 !important;
    background: #F0F0F0 !important;
}
.chat:hover{
    cursor: pointer;
}
.chat.media{
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.cactive .chat{
    background: #fefefe;
}
.calert .chat{
    border-right: solid 5px #FF7676 !important
}
.ctop .chat{
    border-left: solid 3px #98E4FF !important;
}
.ctop2 .chat{
    border-left: solid 3px #B2A5FF !important;
    border: 2px solid #DAD2FF !important;
    background: #FFFAEC;
}
.lastmsg{
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.settings-tray {
  background: #eee;
  padding: 10px 15px;
  border-radius: 7px;
  
  .no-gutters {
    padding: 0;
  }
  
  &--right {
    float: right;
    
    i {
      margin-top: 10px;
      font-size: 25px;
      color: grey;
      margin-left: 14px;
      transition: .3s;
      
      &:hover {
        color: $blue;
        cursor: pointer;
      }
    }
  }
}

.search-box {
  background: #fafafa;
  padding: 10px 13px;
  
  .input-wrapper {
    background: #fff;
    border-radius: 40px;
    
    i {
      color: grey;
      margin-left: 7px; 
      vertical-align: middle;
    }
  }
}
.hover:hover{
    cursor: pointer;
    color: #64CCC5;
}
input {
  border: none;
  border-radius: 30px;
  width: 80%;

  &::placeholder {
    color: #e3e3e3;
    font-weight: 300;
    margin-left: 20px;
  }

  &:focus {
    outline: none;
  }
}
.contacts{
    height: 100%;
}
.contatslist{
    overflow-y: scroll;
    height: 85vh;
}
.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background: #fff;
  transition: .3s ease;
  
  &--grey {
    background: #eee;
  }
  
  .text {
    margin-left: 12px;
    width: 70%;
    
    h6 {
      margin-top: 6px;
      margin-bottom: 0;
    }
    
    p {
      margin: 0;
    }
  }
  
  .time {
    color: grey;
  }
  
  &--onhover:hover {
    background: $blue;
    cursor: pointer;
    
    p,
    h6,
    .time, * {
      color: #fff !important;
    }
  }
}

hr {
  margin: 5px auto;
  width: 60%;
}

.chat-bubble {
  padding: 10px 14px;
  background: #eee;
  margin: 10px 30px;
  border-radius: 9px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom: 0;
    margin-top: -10px;
  }
  
  &--left {
     &:after {
      left: 0;
      border-right-color: #eee;
	    border-left: 0;
      margin-left: -20px;
    }
  }
  
  &--right {
    &:after {
      right: 0;
      border-left-color: $blue;
	    border-right: 0;
      margin-right: -20px;
    }
  }
}


.offset-md-6 {
  .chat-bubble {
    background: $blue;
    color: #fff;
  }
}

.chat-box-tray {
  background: #eee;
  display: flex;
  align-items: baseline;
  padding: 10px 15px;
  align-items: center;
  margin-top: 19px;
  bottom: 0;
  
  input {
    margin: 0 10px;
    padding: 6px 2px;
    font-size: 18px;
  }
  
  i {
    color: grey;
    font-size: 30px;
    vertical-align: middle;
    
    &:last-of-type {
      margin-left: 25px;
    }
  }
}
.main-row{
    height: 100vh;
}
.tem{
    background: #fafafa;
    cursor: pointer;
    margin-top: 3px;
}
.tem:hover{
    background: #eee !important;
    cursor: pointer;
}
.cccs:hover{
    color: blue !important
}
.active-tag{
    background: #eee !important;
}
.locked *{
    color: #D0D4CA !important;
}
</style>